import * as React from 'react';
import LocationCardSection from '../LocationCardSection/LocationCardSection';
import CtaSection from '../CtaSection';
import Hero from '../Hero';
import WhatsOn from '../WhatsOn';
import IntroSection from '../KeyFeatures';

const HomePage = ({ page }) => {
  return (
    <>
      <Hero page={page}>
        <IntroSection
          title={page.introSectionTitle}
          items={page.introSectionItems}
        />
      </Hero>
      <CtaSection ctaSectionCards={page.ctaSectionCards} />
      <LocationCardSection heading={'Our locations'} venues={page.venues} />
      <WhatsOn description={page.groupsDescription} images={page.imageSlider} />
    </>
  );
};

export default HomePage;
