import styled from 'styled-components';

import BookButton from 'components/Button/BookButton';
import RichText from './RichText';

import BREAKPOINTS from 'styles/breakpoints';
import COLORS from 'styles/colors';
import { H3, H5, H6, P1 } from 'styles/typography';

const Section = styled.section`
  padding: 110px 0;
  color: ${COLORS.purpleDark};
  background-color: ${COLORS.lilac.opacity(0.45)};
  ${BREAKPOINTS.small`
    padding: 125px 0 150px;
  `}
`;

const Container = styled.section`
  max-width: 1440px;
  margin: 0 auto;
`;

const SectionHeading = styled(H3)`
  margin-bottom: 65px;
  text-align: center;
  ${BREAKPOINTS.small`
    margin-bottom: 100px;
  `}
`;

const CardsContainer = styled.div`
  ${BREAKPOINTS.medium`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  `}

  ${BREAKPOINTS.xLarge`
    gap: 55px;
  `}
`;

const Hr = styled.hr`
  margin: 24px 0;
  width: 100%;
  border: 0;
  height: 1px;
  background-color: ${COLORS.purple.opacity(0.2)};

  ${BREAKPOINTS.medium`
    margin: 16px 0 8px;
  `}
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  ${Hr} {
    order: 4;
    &:last-of-type {
    }
    ${BREAKPOINTS.medium`
    order: initial;
  `}
  }
`;

const CardTitle = styled(H5)`
  margin-bottom: 10px;
  ${BREAKPOINTS.small`
    margin-bottom: 24px;
  `}
`;

const CardBody = styled.div`
  p {
    margin: 0;
  }
  margin-bottom: 18px;
  ${BREAKPOINTS.medium`
    margin-bottom: 0;
  `}
`;

const PriceContainer = styled.div`
  p {
    margin: 0;
  }
  display: grid;
  gap: 8px;
`;

const CardCtaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  ${BREAKPOINTS.medium`
    flex-direction: column;    
    height: 100%;
    ${PriceContainer} {
        margin-bottom: 24px;
    }
    div:last-of-type {
         margin-left: auto;
         margin-right: auto;
    }
  `}
`;

const Price = ({ price }) => (
  <PriceContainer>
    <P1 weight="semibold">From</P1>
    <H6 weight="bold">{price} pp</H6>
  </PriceContainer>
);

const CtaCard = ({ title, body, price, ctaLabel }) => {
  return (
    <CardWrapper>
      <CardTitle small weight="bold">
        {title}
      </CardTitle>
      <CardBody>{body}</CardBody>
      <Hr />
      <CardCtaWrapper>
        <Price price={`£ ${price}`} />
        <BookButton label={ctaLabel} size="medium" />
      </CardCtaWrapper>
    </CardWrapper>
  );
};

const CtaSection = ({ ctaSectionCards = [] }) => {
  return (
    <Section>
      <Container>
        <SectionHeading weight="bold">Ready, Aim, Book</SectionHeading>
        <CardsContainer>
          {ctaSectionCards.map((card) => (
            <CtaCard
              key={card.id}
              title={card.title}
              body={<RichText html={card.body.html} />}
              price={card.price}
              ctaLabel={card.ctaLabel}
            />
          ))}
        </CardsContainer>
      </Container>
    </Section>
  );
};

export default CtaSection;
