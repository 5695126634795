import { useState } from 'react';
import styled, { css } from 'styled-components';

import AnimateSvgText from 'components/AnimateSvgText';
import ImageLightBox from 'components/ImageLightBox';
import ImageSlider from 'components/ImageSlider';
import RichText from 'components/RichText';

import useInView from 'hooks/useInView';

import { WHATS_ON_HASH_ID } from 'utils/constants';

import BREAKPOINTS from 'styles/breakpoints';
import { CircleMask } from 'styles/global';
import COLORS from 'styles/colors';

import TextSvg from 'public/images/text/whats-on-at-clays.svg';

const Section = styled.section`
  position: relative;
  min-height: 560px;
  color: ${COLORS.lilac};

  ${({ bgColorNone }) =>
    !bgColorNone &&
    css`
      background-color: ${COLORS.lilac.opacity(0.45)};
    `}

  ${BREAKPOINTS.small`
    min-height: 831px;
  `}

  ${BREAKPOINTS.medium`
    min-height: 878px;
  `}

  ${BREAKPOINTS.large`
    min-height: 976px;
  `}
`;

const Title = styled.div`
  margin: max(50px, min(8vw, 80px)) 0;
  min-height: 51px;

  ${BREAKPOINTS.small`
    margin: 80px 0;
  `}

  ${BREAKPOINTS.medium`
    min-height: max(50px, min(5vw, 120px));
  `}

  ${BREAKPOINTS.large`
    margin: max(90px, min(8vw, 116px)) 0;
  `}
`;

const Content = styled.div`
  margin: 0 auto 70px;
  padding: 0 65px;
  position: relative;
  max-width: 420px;
  text-align: center;
  box-sizing: content-box;

  ${BREAKPOINTS.small`
    margin-bottom: 73px;
  `}
`;

//  Same components used in src/components/LandingPage/WhatsOn.js
// TODO: Refactor location page to use this component
const WhatsOn = ({ description, images, bgColorNone }) => {
  const [inViewRef, inView] = useInView(false);
  const [activeImageIdx, setActiveImageIdx] = useState(0);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);

  const handleOpenImageLightBox = (idx) => {
    setIsLightBoxOpen(true);
    setActiveImageIdx(idx);
  };

  const handleCloseImageLightBox = () => {
    setIsLightBoxOpen(false);
  };

  return (
    <Section id={WHATS_ON_HASH_ID} ref={inViewRef} bgColorNone={bgColorNone}>
      <CircleMask>
        <Title>
          <AnimateSvgText
            elem={TextSvg}
            containerRef={inViewRef}
            selector="animate-path-corporate"
            inView={inView}
            min={400}
            max={625}
          />
        </Title>
        <Content>
          <RichText html={description?.html} />
        </Content>

        <ImageSlider
          images={images}
          shouldAutoPlay={!isLightBoxOpen}
          handleOpenImageLightBox={handleOpenImageLightBox}
          imageSize="landscape"
        />
      </CircleMask>

      {isLightBoxOpen && (
        <ImageLightBox
          images={images}
          activeImageIdx={activeImageIdx}
          handleClose={handleCloseImageLightBox}
        />
      )}
    </Section>
  );
};

export default WhatsOn;
