import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import styled from 'styled-components';

import useInView from 'hooks/useInView';
import useIsolateNav from 'hooks/useIsolateNav';

import BREAKPOINTS from 'styles/breakpoints';
import COLORS from 'styles/colors';
import TYPO, { TextStroke } from 'styles/typography';

import { isClient, isMobileDevice } from 'utils/helpers';
import { AWS_S3_BUCKET_URL } from 'utils/constants';

const Container = styled.section`
  background-color: ${COLORS.forest};
  overflow-x: hidden;

  ${BREAKPOINTS.medium`
    background-image: url('/images/backgrounds/bg-roundel-light.svg'), url('/images/backgrounds/bg-roundel-light.svg');
    background-position: left -450px bottom -450px, calc(100vw - 200px) 90vh;
    background-repeat: no-repeat;
  `}
`;

const HeroSection = styled.div`
  --landing-hero-height: 540px;

  margin-bottom: 256px;
  position: relative;

  ${BREAKPOINTS.small`
    --landing-hero-height: 70vh;
    margin-bottom: 300px;
  `}

  ${BREAKPOINTS.medium`
    --landing-hero-height: 95vh;
    margin-bottom: 200px;
  `}

  ${BREAKPOINTS.large`
      --landing-hero-height: 100vh;
    `};
`;

const Background = styled.div`
  position: relative;
  height: var(--landing-hero-height);
  overflow: hidden;
  z-index: 10;
  background: ${({ bgImg }) => (bgImg ? `url(${bgImg.mobile})` : 'none')}
    no-repeat center top;

  ${BREAKPOINTS.small`
    background-size: cover;
    background-image: ${({ bgImg }) => (bgImg ? `url(${bgImg.url})` : 'none')};
  `}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.black.opacity(0.2)};
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Heading = styled.div`
  position: absolute;
  top: 482px;
  width: 100%;

  ${BREAKPOINTS.small`
    top: calc(var(--landing-hero-height) - 94px);
  `}

  ${BREAKPOINTS.medium`
    padding: 0 20px;
    top: calc(var(--landing-hero-height) - 136px);
    transform: var(--hero-transform);
  `}

  ${BREAKPOINTS.large`
    padding: 0;
    top: calc(var(--landing-hero-height) - 167px);
  `}
`;

const H1 = styled.h1`
  ${TYPO.h1}
  margin: 0 auto;
  display: block;
  max-width: 414px;
  text-align: center;
  color: ${COLORS.pink};

  ${BREAKPOINTS.small`
    max-width: 85%;
  `}

  ${BREAKPOINTS.medium`
    max-width: 100%;
  `}

  ${BREAKPOINTS.large`
    max-width: 1200px;
  `}

  ${({ outline }) => outline && TextStroke}
`;

const Hero = ({ page, children }) => {
  const { title, heroVideoUrl, heroAsset, videoPoster } = page;

  const [inViewRef, inView] = useInView(false);
  useIsolateNav(inView);

  const [videoError, setVideoError] = useState(false);

  const videoRef = useRef(null);
  const scrollY = useRef(0);
  const distance = useRef(0);
  const startOffset = useRef(0);
  const hasScrolled = useRef(false);
  const animationFrameId = useRef(null);

  // Check if video reel fails to load
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const loadVideo = () => {
      video.load();
    };

    const onError = () => {
      setVideoError(true);
    };

    video.addEventListener('error', onError);
    loadVideo();

    return () => {
      video.removeEventListener('error', onError);
    };
  }, [heroVideoUrl]);

  // Reset scroll values on page load
  useEffect(() => {
    distance.current = 0;
    scrollY.current = 0;
    startOffset.current = 0;

    const root = window.document.documentElement;
    root.style.setProperty('--hero-transform', `translate3d(0, 0, 0)`);
  }, []);

  useEffect(() => {
    const interpolate = 20;
    const root = window.document.documentElement;

    if (!isClient()) return null;

    if (!inView) {
      hasScrolled.current = false;
      cancelAnimationFrame(animationFrameId.current);
    }

    const animateParallaxScroll = () => {
      distance.current = scrollY.current - startOffset.current;
      startOffset.current += distance.current / interpolate;

      if (scrollY.current > 450) {
        root.style.setProperty('--hero-fade-in', 1);
      } else {
        root.style.setProperty('--hero-fade-in', 0);
      }

      root.style.setProperty(
        '--hero-transform',
        `translate3d(0, -${(startOffset.current * 0.6).toFixed(4)}px, 0)`
      );

      animationFrameId.current = requestAnimationFrame(animateParallaxScroll);
    };

    const scroll = () => {
      scrollY.current =
        window.pageYOffset || document.documentElement.scrollTop;

      if (!hasScrolled.current) {
        hasScrolled.current = true;
        animationFrameId.current = requestAnimationFrame(animateParallaxScroll);
      }
    };

    const throttleScroll = throttle(scroll, 200);

    if (inView && !isMobileDevice()) {
      window.addEventListener('scroll', throttleScroll, false);
    }
    return () => {
      cancelAnimationFrame(animationFrameId.current);
      throttleScroll.cancel();
      window.removeEventListener('scroll', throttleScroll);
    };
  }, [inView]);

  return (
    <Container ref={inViewRef}>
      <HeroSection>
        <Background bgImg={heroAsset}>
          {!heroAsset && heroVideoUrl && (
            <>
              <BackgroundVideo
                key={heroVideoUrl}
                ref={videoRef}
                muted
                playsInline
                autoPlay
                loop
                poster={videoError ? videoPoster?.url : null}
              >
                <source
                  src={`${AWS_S3_BUCKET_URL}/${heroVideoUrl}`}
                  type="video/mp4"
                />
              </BackgroundVideo>

              <Overlay />
            </>
          )}

          <Heading style={{ '--hero-transform': null }}>
            <H1>{title}</H1>
          </Heading>
        </Background>
        <Heading style={{ '--hero-transform': null }}>
          <H1 as="span" outline>
            {title}
          </H1>
        </Heading>
      </HeroSection>

      {children}
    </Container>
  );
};

export default Hero;
